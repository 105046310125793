import { useEffect, useState, useCallback } from "react";
import { adminAuthClient } from "../utils/supabase";
import DeleteUserModal from "../components/DeleteUser";

// Debounce function to delay fetching during typing
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [deleteUser, setDeleteUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Initial fetch for users when component mounts
    fetchUsers();
  }, []);

  // Function to fetch all users
  const fetchUsers = async () => {
    const { data: { users }, error } = await adminAuthClient.listUsers({
      perPage: 2000, // Fetch all users (or adjust as needed)
    });

    if (error) {
      console.error("Error fetching users:", error);
      return;
    }

    setUsers(users);
    setFilteredUsers(users); // Initially, show all users
  };

  // Function to filter users based on search query
  const filterUsers = useCallback(
    debounce((query) => {
      if (!query) {
        setFilteredUsers(users); // If empty query, reset to all users
      } else {
        const lowerCaseQuery = query.toLowerCase();
        const matchingUsers = users.filter((user) =>
          (user.user_metadata?.name?.toLowerCase().includes(lowerCaseQuery) ||
            user.email.toLowerCase().includes(lowerCaseQuery) ||
            user.user_metadata?.username?.toLowerCase().includes(lowerCaseQuery))
        );
        setFilteredUsers(matchingUsers);
      }
    }, 300),
    [users]
  );

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterUsers(query); // Call debounced filter function
  };

  return (
    <div className="w-full h-screen overflow-y-scroll py-5">
      <div className="flex justify-between w-full items-center">
        <div>
          <h1>Users</h1>
          <p className="text-gray-400">Manage users</p>
        </div>
        <input
          type="text"
          placeholder="Search users..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="w-1/2 px-3 py-1 border border-blue-500 rounded-md text-black bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340] overflow-x-hidden">
        <div className="overflow-x-scroll">
          <table className="w-full table-auto md:p-10 select-none">
            <thead>
              <tr className="border-b-[1px] border-gray-600">
                <th className="py-2 text-left pl-3">#</th>
                <th className="py-2 text-left pl-3">Name</th>
                <th className="py-2 text-left pl-3">Email</th>
                <th className="py-2 text-left pl-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr
                  key={user.id}
                  className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30"
                >
                  <td className="py-2 text-left pl-3">{index + 1}</td>
                  <td className="py-2 text-left pl-3">
                    {user.user_metadata?.name || user.user_metadata?.username}
                  </td>
                  <td className="py-2 text-left pl-3">{user.email}</td>
                  <td className="py-2 text-left pl-3">
                    <button
                      className="px-3 py-1 bg-sky-500 text-white text-sm rounded-md"
                      onClick={() => setDeleteUser(user)}
                    >
                      Delete
                    </button>
                  </td>
                  {deleteUser && deleteUser.id === user.id && (
                    <DeleteUserModal user={user} setShowModal={setDeleteUser} />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Users;
