import { IoMdArrowForward } from "react-icons/io";

function Card({ title, value, icon }) {
  return (
    <div className='bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer w-64'>
      <div className='flex items-center gap-4 mb-4'>
        <div className='w-12 h-12 bg-[#1091FF] text-white flex justify-center items-center rounded-full'>
          {icon}
        </div>
        <div>
          <h3 className='font-semibold text-gray-700'>{title}</h3>
          <p className='font-bold text-2xl text-gray-900'>{value}</p>
        </div>
      </div>
      <div className='flex justify-between text-gray-500 text-xs'>
        <p>Since last month</p>
        <div><IoMdArrowForward /></div>
      </div>
    </div>
  );
}

export default Card;
