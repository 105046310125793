import { useEffect, useState } from "react";
import { supabase } from "../utils/supabase";
import { differenceInDays, parseISO } from "date-fns";
import EditSubscriptionModal from "../components/EditSubscription";
import Card from '../components/Card';
import { PiFilmSlateBold } from "react-icons/pi";
import { RiMovie2Fill } from "react-icons/ri";

// SubscriptionStats Component
const SubscriptionStats = ({ subscriptions = [] }) => {
  const subs = Array.isArray(subscriptions) ? subscriptions : [];

  const basicSubs = subs.filter(sub => sub?.basic).length;
  const standardSubs = subs.filter(sub => sub?.standard).length;
  const totalSubs = basicSubs + standardSubs;

  const getPercentage = (count) => {
    return totalSubs === 0 ? 0 : ((count / totalSubs) * 100).toFixed(1);
  };

  const stats = [
    {
      label: 'Total Subscriptions',
      count: totalSubs,
      color: 'bg-gray-500',
      percentage: '100'
    },
    {
      label: 'Standard',
      count: standardSubs,
      color: 'bg-indigo-500',
      percentage: getPercentage(standardSubs)
    },
    {
      label: 'Basic',
      count: basicSubs,
      color: 'bg-blue-500',
      percentage: getPercentage(basicSubs)
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      {stats.map((stat) => (
        <div key={stat.label} className="bg-[#1C2340] rounded-lg p-4 shadow">
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">{stat.label}</p>
            <div className="flex items-baseline justify-between">
              <h3 className="text-2xl font-semibold text-white">{stat.count}</h3>
              <p className="text-sm text-gray-400">{stat.percentage}%</p>
            </div>
            <div className="mt-4 w-full bg-gray-700 rounded-full h-2">
              <div
                className={`${stat.color} h-2 rounded-full`}
                style={{ width: `${stat.percentage}%` }}
              ></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Main Subscriptions Component
function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalSubscriptions, setTotalSubscriptions] = useState(null);
  const ITEMS_PER_PAGE = 50;

  function calculateDaysToTargetDate(targetDate) {
    if (!targetDate) return 0;
    const parsedTargetDate = parseISO(targetDate);
    const currentDate = new Date();
    const daysDifference = differenceInDays(parsedTargetDate, currentDate);
    return daysDifference > 0 ? daysDifference : 0;
  }

  const getSubscriptions = async (searchQuery = "", filter = "", pageNumber = 0) => {
    setLoading(true);

    try {
      let query = supabase
        .from("subscriptions")
        .select("*, profiles!inner(*)", { count: "exact" });

      if (searchQuery) {
        query = query.ilike("profiles.email", `%${searchQuery.trim()}%`);
      }

      if (filter) {
        switch (filter) {
          case "basic":
            query = query.eq("basic", true);
            break;
          case "standard":
            query = query.eq("standard", true);
            break;
        }
      }

      const start = pageNumber * ITEMS_PER_PAGE;
      const end = start + ITEMS_PER_PAGE - 1;
      query = query.range(start, end);

      const { data, error, count } = await query;

      if (error) throw error;

      const validData = data?.filter(sub => sub?.profiles?.email) || [];

      if (pageNumber === 0) {
        setSubscriptions(validData);
      } else {
        setSubscriptions(prev => [...prev, ...validData]);
      }

      setHasMore(count > (pageNumber + 1) * ITEMS_PER_PAGE);

    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTotalUserCount = async () => {
    const { count, error } = await supabase
      .from('profiles')
      .select('id', { count: 'exact' });
    if (count) {
      setTotalUsers(count);
    }
  };

  const getTotalSubscriptions = async () => {
    const { count, error } = await supabase
      .from('subscriptions')
      .select('id', { count: 'exact' });
    if (count) {
      setTotalSubscriptions(count);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPage(0);
      getSubscriptions(searchText, filterBy, 0);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchText, filterBy]);

  useEffect(() => {
    getTotalUserCount();
    getTotalSubscriptions();
  }, []);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      getSubscriptions(searchText, filterBy, nextPage);
    }
  };

  return (
    <div className="w-full h-screen overflow-y-scroll py-5 px-4" onScroll={handleScroll}>
      <div className="flex justify-between w-full items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-white">Subscriptions</h1>
          <p className="text-gray-400">Manage subscriptions</p>
        </div>
      </div>

      <div className='flex gap-4 mb-6'>
        <Card title='TOTAL USERS' value={totalUsers} icon={<PiFilmSlateBold size={25} />} />
        <Card title='TOTAL SUBSCRIPTIONS' value={totalSubscriptions} icon={<RiMovie2Fill size={25} />} />
      </div>

      <SubscriptionStats subscriptions={subscriptions} />

      <div className="mb-24 my-10 px-3 py-3 bg-[#1C2340] overflow-x-hidden rounded-lg">
        <div className="overflow-x-scroll">
          <table className="w-full table-auto md:p-10 select-none">
            <caption className="py-3 px-3">
              <section className="flex justify-between items-center w-full gap-4">
                <input
                  type="text"
                  placeholder="search"
                  className="px-3 py-2 rounded-lg placeholder:text-[#bcbfc2] outline outline-1 outline-[#f4f3f7] bg-transparent w-full"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                />

                <select
                  className="px-3 py-2 ml-2 bg-transparent rounded-lg outline outline-1 outline-[#ededed] text-sm"
                  value={filterBy}
                  onChange={(event) => setFilterBy(event.target.value)}
                >
                  <option value="">All Plans</option>
                  <option value="basic">Basic Premium</option>
                  <option value="standard">Standard Premium</option>
                </select>
              </section>
            </caption>
            <thead>
              <tr className="border-b-[1px] border-gray-600">
                <th className="py-2 text-left pl-3">#</th>
                <th className="py-2 text-left pl-3">Email</th>
                <th className="py-2 text-left pl-3">Subscription (days)</th>
                <th className="py-2 text-left pl-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((sub, index) => (
                <tr
                  key={sub.id}
                  className="cursor-pointer mb-10 text-gray-400 border-b-[1px] border-gray-700 hover:bg-gray-600/30"
                >
                  <td className="py-2 text-left pl-3">{index + 1}</td>
                  <td className="py-2 text-left pl-3">{sub.profiles?.email || 'No email'}</td>
                  <td className="py-2 text-left pl-3">
                    {sub.basic
                      ? `Basic (${calculateDaysToTargetDate(sub.end_date)})`
                      : ""}
                    {sub.standard
                      ? `Standard (${calculateDaysToTargetDate(sub.end_date)})`
                      : ""}
                  </td>
                  <td className="py-2 text-left pl-3">
                    <button
                      className="px-3 py-1 bg-sky-500 text-white text-sm rounded-md"
                      onClick={() => setSelectedIndex(sub)}
                    >
                      Edit
                    </button>
                  </td>
                  {selectedIndex && selectedIndex.id === sub.id && (
                    <EditSubscriptionModal
                      sub={sub}
                      setShowAddSeason={setSelectedIndex}
                    />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="text-center py-4 text-gray-400">Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
